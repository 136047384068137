// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDveOFtOFsV9tY8FQzpBnDYWor9BXzPXcI",
  authDomain: "pokeren-c0552.firebaseapp.com",
  databaseURL: "https://pokeren-c0552-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pokeren-c0552",
  storageBucket: "pokeren-c0552.appspot.com",
  messagingSenderId: "80759239990",
  appId: "1:80759239990:web:dca7fd4a20bb92b2272f97"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
